import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "reactstrap"
import ContactImg from "../components/contactimg"
import styled from "styled-components"

const ConfirmPage = () => (
  <Layout>
    <SEO title="Please check your email now..." />
    <Container fluid={true}>
      <Row>
        <Col lg="6" className="dual-left p-0">
          <ImageBorder>
            <ContactImg />
          </ImageBorder>
        </Col>
        <Col lg="6" className="about-content px-sm-5 py-4">
          <div>
            <h1 className="text-center">Please Check Your Email Now...</h1>
            <hr />
            <p>
              <em>Nearly!</em>
            </p>
            <p>We've sent you something really important.</p>
            <p>
              Please <b>check your email</b> now.
            </p>
            <p>
              You'll find a confirmation link there that will confirm your
              subscription.
            </p>
            <p>We'll wait here while you check...</p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ConfirmPage

const ImageBorder = styled.div`
  border-right: 4px solid #f56545;
  @media (max-width: 980px) {
    border-right: none;
    border-bottom: 4px solid #f56545;
  }
`
